import * as React from "react"
import * as styles from './Gallery.module.scss'
import '../../styles/columnBreakpoints.scss';
import Img from 'gatsby-image'

const Gallery = ({topContent, bottomContent, galleryImages}) => {
  const galleryContent = [];
  for (let galleryImage of galleryImages) {
    galleryContent.push(<Img fluid={galleryImage.node.Image.localFile.childImageSharp.fluid} alt={galleryImage.node.Title}/>)
  }
  return (
    <div className={styles.gallery}>
      <div className={`column ${styles.column}`}>
        {topContent}
      </div>
      <div className={styles.imagesContainer}>
        {galleryContent}
      </div>
      <div className={`column ${styles.column}`}>
        {bottomContent}
      </div>
    </div>
  )
}

export default Gallery;