import React from "react"
import * as styles from './SignUp.module.scss'

const SignUp = () => {
  return (
    <div className={styles.signUp}>
      <iframe src="https://landing.mailerlite.com/webforms/landing/v1x1p9" title='Miler Lite' scrolling='no'/>
    </div>
  )
}

export default SignUp;