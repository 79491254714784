import * as React from "react";
import {graphql} from "gatsby";
import Layout from '../components/Layout/Layout';
import WideImageSection from '../components/WideImageSection/WideImageSection';
import FrontBanner from '../components/FrontBanner/FrontBanner';
import SignUp from '../components/SignUp/SignUp';
import CenteredSection from '../components/CenteredSection/CenteredSection';
import Gallery from '../components/Gallery/Gallery';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const anchorTag = (title) => {
  const re = new RegExp("[^a-zA-Z0-9-]", "g");
  return title.replaceAll(" ","-").replaceAll(re, "").toLowerCase();
}

const IndexPage = ({data}) => {

  const topGalleryContent = (
    <div>
        This is your new neighborhood hangout, a place to exercise, relax, and socialize.
        Enjoy state-of-the-art climbing walls in a unique, community-oriented venue in your neighborhood. Whether this is your first time on the wall, or you are working on that V-hard, you'll find an inspring challenge here.
    </div>
  );

  const bottomGalleryContent = (
    <div>
      11,000 sq ft foot facility, 5,000 sq ft bouldering walls, moonboard and campus board, learning area with one-of-a-kind climbing castle, yoga studio, workout area, space to socialize, and specialty retail.
    </div>
  )

  const hmbVideo = <iframe title='Half Moon Bouldering' src="https://www.youtube.com/embed/o2g87XfuY40?rel=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen width="50%"></iframe>
  const homeSections = data.allStrapiArticle.edges;
  const galleryImages = data.allStrapiGalleryImage.edges;
  return (
    <Layout>
      <FrontBanner bannerSlides={data.allStrapiBannerSlide.edges}/>
      <Gallery topContent={topGalleryContent} bottomContent={bottomGalleryContent} galleryImages={galleryImages} />
      <CenteredSection htmlContent={hmbVideo}/>
      {homeSections.map(document => (
        <div>
          {document.node.HeaderImage ? <WideImageSection image={document.node.HeaderImage.localFile} heading={document.node.HeaderText} blank={true}/> : null }
          {document.node.Title ? <CenteredSection><span class='bookmark' id={anchorTag(document.node.Title)}></span><h3>{document.node.Title}</h3></CenteredSection> : null}
          <CenteredSection content={document.node.Content}/>
        </div>
      ))}
      <SignUp/>
    </Layout>
  )
}

export default IndexPage;

export const pageQuery=graphql`
  query IndexQuery {
    allStrapiArticle(sort: {order: ASC, fields: Index}, filter: {Page: {eq: "index"}}) {
      edges {
        node {
          HeaderText
          HeaderImage {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          Title
          Content
        }
      }
    }
    allStrapiBannerSlide(sort: {order: ASC, fields: Index}) {
      edges {
        node {
          Title
          SubTitle
          SummaryTitle
          LinkUrl
          Image {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    allStrapiGalleryImage(sort: {order: ASC, fields: Index}, filter: {Page: {eq: "index"}}) {
      edges {
        node {
          Image {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 960) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`