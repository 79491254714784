import React, { useRef } from "react"
import * as styles from './FrontBanner.module.scss'
import {Carousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const FrontBanner = ({bannerSlides}) => {
  const summaryRefs = useRef([]);
  let previousSummaryIndex = -1;

  const highlightSummary = (index) => {
    if(summaryRefs.current[index]) summaryRefs.current[index].className = styles.selected;
    if(summaryRefs.current[previousSummaryIndex]) summaryRefs.current[previousSummaryIndex].className='';
    previousSummaryIndex = index;
  }

  const slide = (document) => {
    return (
      <div>
        <img src={document.node.Image.localFile.childImageSharp.fluid.src} alt={`img`}/>
        <div className={styles.slideText}>
          <h2 className='column'>{document.node.Title}</h2>
          <h3 className='column'>{document.node.SubTitle}</h3>
        </div>
      </div>
    )    
  }

  const summaryClick = (index) => {
    document.getElementsByClassName("dot")[index].click();
  }

  return (
    <div className={styles.container}>
      <Carousel 
        showArrows={false} 
        autoPlay={true} 
        infiniteLoop={true} 
        stopOnHover={false} 
        interval={5000} 
        showThumbs={false}
        onChange={highlightSummary}
      >
        {bannerSlides.map((document, index) => (
          document.node.LinkUrl?
            (<a href={document.node.LinkUrl} >
              {slide(document)}
            </a>) :
            slide(document)
        ))}
      </Carousel>
      <div className={styles.summary}>
      {bannerSlides.length > 1 && bannerSlides.map((document, index) => (
        document.node.SummaryTitle && (
          <button 
            onClick={() => {summaryClick(index)}}
            ref={(el) => summaryRefs.current[index] = el}
          >
            {document.node.SummaryTitle}
          </button>
      )))}
      </div>
    </div>
  )
}

export default FrontBanner;